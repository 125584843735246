import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'

import { ReUseHTwotag } from './ReUseHTwoTag'

import ResourcesCardVideosData from '../Data/ResourcesCardVideosData.json'

const AppTabPanelResourcesAllVideosCardFrontWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .MainReourcesCardsection {
    padding: 0px 30px 20px 30px;
    @media (max-width: 1090px) {
      padding: 0px 15px 2rem 15px;
    }
  }
  .CardReourcesSection {
    display: flex;
    max-width: 1590px;
    justify-content: space-evenly;
    margin: auto;

    @media (max-width: 7680px) {
      max-width: 1590px;
      flex-wrap: wrap;
    }
  }
  .ReourcesCardsection {
    width: 400px;
    height: auto;
    box-shadow: 2px 4px 10px 0 rgb(0 0 0 / 10%);
    border: 1px solid #34a1d5;
    margin: 20px 0px 20px 0px;
    border-radius: 12px;
  }
  .ReourcesCardsection:hover {
    box-shadow: rgb(160 161 163 / 88%) 0px 3px 7px 0px;
    border: 2px solid #34a1d5;
  }

  .ReourcesCardDatePara {
    font-size: 1rem;
    padding: 10px 10px 0px 10px;
  }
  .ReourcesCardHeading {
    font-size: 21px;
    padding: 0px 10px 0px 10px;
    color: #1f1f1f;
  }
  .ReourcesCardPara {
    font-size: 1rem;
    padding: 0px 10px 0px 10px;
  }
  .ReourcesCardImage {
    width: 100%;
    height: 280px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    border: none;
  }
  .ButtonSectionReourcesCard {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .ReourcesCardButton:hover {
    color: rgb(4, 124, 194);
    text-decoration: dotted;
  }
  .CardLinkHub {
    text-decoration: none;
  }
  .achorPara {
    font-size: 1rem;
    font-weight: bold;
    color: #34a1d5;
    padding-left: 6px;
    padding-right: 6px;
  }
`

export const AppTabPanelResourcesAllVideosCardFront = () => {
  return (
    <AppTabPanelResourcesAllVideosCardFrontWapper>
      <div className="MainReourcesCardsection">
        <div className="CardReourcesSection">
          {ResourcesCardVideosData.map((data, index) => {
            if (index <= 2) {
              return (
                <div className="ReourcesCardsection" key={index}>
                  {/* <div className="">
                    <video className="ReourcesCardImage" controls muted loop src={data.ImagePath} />
                  </div> */}
                  <div className="WelcomeButtonSection">
                    <iframe
                      src={data.ImagePath}
                      className="ReourcesCardImage"
                      // frameborder="0"
                      allow="autoplay; fullscreen"
                      // allowfullscreen
                    />
                  </div>
                  <div className="CardReourcesContentSection">
                    <ReUseHTwotag Heading={data.title} HeadingStyle="ReourcesCardHeading" />
                    <ReUsePtag para={data.DatePara} paraStyle="ReourcesCardDatePara" />
                  </div>
                </div>
              )
            }
            return null // Return null for indices greater than 2
          })}
        </div>
      </div>
    </AppTabPanelResourcesAllVideosCardFrontWapper>
  )
}
