import React, { useEffect } from 'react'
import styled from 'styled-components'
import { AppReourcesCardFront } from './AppReourcesCardFront'
import ReourcesCardEmailData from '../Data/ReourcesCardEmailData.json'
import { AppTabPanelResourcesVideosCardFront } from './AppTabPanelResourcesVideosCardFront'
import { AppTabPanelResourcesAllCardFront } from './AppTabPanelResourcesAllCardFront'
import { AppTabPanelResourcesAllVideosCardFront } from './AppTabPanelResourcesAllVideosCardFront'
import { AppTabpanelResourcesAllNewsCardFront } from './AppTabpanelResourcesAllNewsCardFront'
import { AppTabPanelResourcesNewsCardFront } from './AppTabPanelResourcesNewsCardFront'
import { AppTabpanelResourcesAllPodcastsCardFront } from './AppTabpanelResourcesAllPodcastsCardFront'
import { AppTabpanelResourcesPodcastsCardFront } from './AppTabpanelResourcesPodcastsCardFront'
import { AppTabpanelResourcesAllWebinarsCardFront } from './AppTabpanelResourcesAllWebinarsCardFront'
import { AppTabpanelResourcesWebinarsCardFront } from './AppTabpanelResourcesWebinarsCardFront'

const AppTabPanelWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .tab {
    display: flex;
    justify-content: center;
    overflow: hidden;
  }

  .tab button {
    background-color: inherit;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 16px;
    @media (max-width: 468px) {
      font-size: 14px;
      padding: 4px 6px;
    }
  }

  .tab button:hover {
    border-bottom: 2px solid #34a1d5;
    font-weight: bolder;
  }

  .tab button.active {
    border-bottom: 2px solid #34a1d5;
    font-weight: bolder;
  }

  .tabcontent {
    display: none;
    padding: 6px 12px;
    border-top: none;
  }
  .TabPanel {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
  }
  .tabcontentcategory {
    margin-left: 72px;
    border-bottom: 2px solid #34a1d5;
    width: 5%;
    padding-bottom: 4px;
    @media (max-width: 468px) {
      margin-left: 16px;
    }
  }
  .CardBTN {
    background-color: #34a1d5;
    color: #fff;
    padding: 10px 30px;
    border: none;
    height: 50px;
    border-radius: 25px;
    font-size: 18px;
    margin-bottom: 30px;
  }

  .CardBTN:hover {
    background-color: #194a8c;
    color: #fff;
    transition-duration: 0.3s;
    transition-timing-function: linear;
    transition-delay: 0s;
    cursor: pointer;
  }
  .BTNContainer {
    display: flex;
    justify-content: center;
  }
`

const AppTabPanel = () => {
  function openCity(catgory: string) {
    const tabcontent = document.getElementsByClassName('tabcontent') as HTMLCollectionOf<HTMLElement>
    for (let i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none'
    }

    const tablinks = document.getElementsByClassName('tablinks')
    for (let i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '')
    }

    const categoryElement = document.getElementById(catgory)
    if (categoryElement) {
      categoryElement.style.display = 'block'
    }

    const categoryTabElement = document.getElementById(catgory + 'tab')
    if (categoryTabElement) {
      categoryTabElement.className += ' active'
    }
  }

  useEffect(() => {
    const allElement = document.getElementById('All')
    if (allElement) {
      allElement.style.display = 'block'
    }
  }, [])

  return (
    <AppTabPanelWapper>
      <div className="TabPanel">
        <div className="tab">
          <button className="tablinks active" id="Alltab" onClick={() => openCity('All')}>
            All
          </button>
          <button className="tablinks" id="Articlestab" onClick={() => openCity('Articles')}>
            Articles
          </button>
          <button className="tablinks" id="Newstab" onClick={() => openCity('News')}>
            Recognition
          </button>
          <button className="tablinks" id="Podcaststab" onClick={() => openCity('Podcasts')}>
            Podcasts
          </button>
          <button className="tablinks" id="Videostab" onClick={() => openCity('Videos')}>
            Videos
          </button>
          <button className="tablinks" id="Webinarstab" onClick={() => openCity('Webinars')}>
            Webinars
          </button>
        </div>
        <div id="All" className="tabcontent">
          <h2 className="tabcontentcategory">Articles</h2>
          <AppTabPanelResourcesAllCardFront />
          <div className="BTNContainer">
            <button className="CardBTN" onClick={() => openCity('Articles')}>
              Show All
            </button>
          </div>
          <h2 className="tabcontentcategory">Recognition</h2>
          <AppTabpanelResourcesAllNewsCardFront />
          <div className="BTNContainer">
            <button className="CardBTN" onClick={() => openCity('News')}>
              Show All
            </button>
          </div>
          <h2 className="tabcontentcategory">Podcasts</h2>
          <AppTabpanelResourcesAllPodcastsCardFront />
          <div className="BTNContainer">
            <button className="CardBTN" onClick={() => openCity('Podcasts')}>
              Show All
            </button>
          </div>
          <h2 className="tabcontentcategory">Videos</h2>
          <AppTabPanelResourcesAllVideosCardFront />
          <div className="BTNContainer">
            <button className="CardBTN" onClick={() => openCity('Videos')}>
              Show All
            </button>
          </div>
          <h2 className="tabcontentcategory">Webinars</h2>
          <AppTabpanelResourcesAllWebinarsCardFront />
          <div className="BTNContainer">
            <button className="CardBTN" onClick={() => openCity('Webinars')}>
              Show All
            </button>
          </div>
        </div>
        <div id="Articles" className="tabcontent">
          <AppReourcesCardFront ResourcesCardEmailData={ReourcesCardEmailData} />
        </div>
        <div id="News" className="tabcontent">
          <AppTabPanelResourcesNewsCardFront />
        </div>
        <div id="Podcasts" className="tabcontent">
          <AppTabpanelResourcesPodcastsCardFront />
        </div>
        <div id="Videos" className="tabcontent">
          <AppTabPanelResourcesVideosCardFront />
        </div>
        <div id="Webinars" className="tabcontent">
          <AppTabpanelResourcesWebinarsCardFront />
        </div>
      </div>
    </AppTabPanelWapper>
  )
}

export default AppTabPanel
