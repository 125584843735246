import React from 'react'
import styled from 'styled-components'
// import { ReUsePtag } from './ReUsePtag'
import { ReuseImgTag } from './ReuseImgTag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import { LinkHubspot } from './LinkHubspot'
import ResourcesCardNewsData from '../Data/ResourcesCardNewsData.json'
// import { Button } from './Button'
import { ResourcesPageButton } from './ResourcesPageButton'

const AppTabpanelResourcesAllNewsCardFrontWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .MainResourcesCardNewsSection {
    padding: 0px 30px 20px 30px;
    @media (max-width: 1090px) {
      padding: 0px 15px 2rem 15px;
    }
  }
  .ResourcesCardNewsSection {
    display: flex;
    max-width: 1590px;
    justify-content: space-evenly;
    margin: auto;

    @media (max-width: 7680px) {
      max-width: 1590px;
      flex-wrap: wrap;
    }
  }
  .ResourcesNewsCardSection {
    width: 400px;
    height: auto;
    box-shadow: 2px 4px 10px 0 rgb(0 0 0 / 10%);
    border: 1px solid #34a1d5;
    margin: 20px 0px 20px 0px;
    border-radius: 12px;
  }
  .ResourcesNewsCardSection:hover {
    box-shadow: rgb(160 161 163 / 88%) 0px 3px 7px 0px;
    border: 2px solid #34a1d5;
  }

  .ReourcesCardDatePara {
    font-size: 1rem;
    padding: 0px 10px 0px 10px;
    margin-bottom: 0;
  }
  .ReourcesCardNewsHeading {
    font-size: 21px;
    padding: 0px 10px 0px 10px;
    margin-bottom: 0;
    color: #1f1f1f;
  }
  .ReourcesCardPara {
    font-size: 1rem;
    padding: 0px 10px 0px 10px;
  }
  .ReourcesCardNewsImage {
    width: 100%;
    height: 280px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
  .ButtonSectionNewsReourcesCard {
    /* margin-top: 20px; */
    margin-bottom: 35px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .ReourcesCardButton:hover {
    text-decoration: dotted;
    color: #194a8c;
  }
  .CardLinkHub {
    text-decoration: none;
  }
  .achorPara {
    font-size: 1rem;
    font-weight: bold;
    color: #34a1d5;
    padding-left: 6px;
    padding-right: 6px;
  }
  .CardReourcesNewsContentSection {
    display: flex;
    /* height: 46%; */
    flex-direction: column;
    height: 40%;
    justify-content: space-between;
    /* margin-top: 30px; */
    /* margin-top: 30px; */
  }
`

export const AppTabpanelResourcesAllNewsCardFront = () => {
  return (
    <AppTabpanelResourcesAllNewsCardFrontWapper>
      <div className="MainResourcesCardNewsSection">
        <div className="ResourcesCardNewsSection">
          {ResourcesCardNewsData.map((data, index) => {
            if (index <= 2) {
              return (
                <div className="ResourcesNewsCardSection" key={index}>
                  <div className="">
                    <ReuseImgTag ImageStyle="ReourcesCardNewsImage" ImagePath={data.ImagePath} AltName="" />
                  </div>
                  <div className="CardReourcesNewsContentSection">
                    <ReUseHTwotag Heading={data.title} HeadingStyle="ReourcesCardNewsHeading" />
                    {/* <ReUsePtag para={data.DatePara} paraStyle="ReourcesCardDatePara" /> */}

                    <div className="ButtonSectionNewsReourcesCard">
                      <LinkHubspot to={data.InternalPageShiftUrl} className="CardLinkHub">
                        <span className="sr-only">Jump to packages</span>
                        <ResourcesPageButton
                          cta={data.eventButtonName}
                          label="Read More"
                          type="submit"
                          className="demobutton"
                        />
                      </LinkHubspot>
                    </div>
                  </div>
                </div>
              )
            }
            return null // Return null for indices greater than 2
          })}
        </div>
      </div>
      {/* <img className="wave-background" src={SignUpData.ImagePath} /> */}
    </AppTabpanelResourcesAllNewsCardFrontWapper>
  )
}
